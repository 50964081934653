// material-ui
import { useTheme } from '@mui/material/styles'
import {
    AppBar,
    AppBarOwnProps,
    IconButton,
    Toolbar,
    useMediaQuery,
} from '@mui/material'

import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Menu } from '../types/common'

interface IProps {
    open: boolean
    navBarMenu: Menu
    handleDrawerToggle: () => void
}

const Header: React.FC<IProps> = (props: IProps) => {
    const { open, navBarMenu, handleDrawerToggle } = props
    const theme = useTheme()
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))
    const iconBackColor = 'grey.100'
    const iconBackColorOpen = 'grey.200'

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                edge="start"
                color="secondary"
                onClick={handleDrawerToggle}
                sx={{
                    color: 'text.primary',
                    bgcolor: open ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0, lg: -2 },
                }}
                size="large"
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <HeaderContent menu={navBarMenu} />
        </Toolbar>
    )

    const appBar: AppBarOwnProps = {
        position: 'fixed',
        color: 'inherit',
        // elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    }

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    )
}

export default Header
