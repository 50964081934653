// material-ui
import { Box, List, Typography } from '@mui/material'
import { MenuItem, MenuSelection, SelectMenuHandler } from '../types/common'

import NavItem from './NavItem'

interface IProps {
    item: MenuItem
    level: number
    drawerOpen: boolean
    menuSelection: MenuSelection | null
    selectMenu: SelectMenuHandler
}

const NavGroup: React.FC<IProps> = (props: IProps) => {
    const { item, drawerOpen, level, menuSelection, selectMenu } = props
    const navCollapse = item.items?.map((menuItem) => {
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Typography
                        key={menuItem.id}
                        variant="caption"
                        color="error"
                        sx={{ p: 2.5 }}
                    >
                        collapse - only available in paid version
                    </Typography>
                )
            case 'item':
                return (
                    <NavItem
                        key={menuItem.id}
                        item={menuItem}
                        drawerOpen={drawerOpen}
                        level={level + 1}
                        selectMenu={selectMenu}
                        menuSelection={menuSelection}
                    />
                )
            default:
                return (
                    <Typography
                        key={menuItem.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Fix - Group Collapse or Items
                    </Typography>
                )
        }
    })

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {navCollapse}
        </List>
    )
}

export default NavGroup
