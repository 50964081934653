import { SuperTokensWrapper } from 'supertokens-auth-react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';
import './i18n';

import registerServiceWorker from './registerServiceWorker';

// scroll bar
import 'simplebar/dist/simplebar.css';

const div = document.getElementById('root') as HTMLElement;
const root = createRoot(div);

root.render(
    <SuperTokensWrapper>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </SuperTokensWrapper>,
);

registerServiceWorker();

