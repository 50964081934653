import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Toolbar, useMediaQuery } from '@mui/material'

// apollo
import { useQuery } from '@apollo/client'
import { GetMenuQueryDocument } from '../apollo/queries'

import Drawer from './MainDrawer'
import Header from './Header'
import { MenuSelection } from '../types/common'
import { getMenu, LOCAL_STORAGE_DRAWER_OPEN } from '../utils/menus'

const MainLayout: React.FC = () => {
    const theme = useTheme()
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

    // drawer open setting
    const [drawerOpen, setDrawerOpen] = useLocalStorage<boolean>(
        LOCAL_STORAGE_DRAWER_OPEN,
        true,
    )

    // query menu
    const { data, loading } = useQuery(GetMenuQueryDocument, {
        variables: {
            ids: [
                'TOP_APP_NAV_BAR_MENU',
                'TOP_APP_SETTINGS_MENU',
                'DRAWER_MENU',
                'FOOTER_MENU',
            ],
        },
    })

    // drawer toggler
    const [open, setOpen] = useState(false)
    const [menuSelection, setMenuSelection] = useState<MenuSelection>({
        ids: [],
    })
    const handleDrawerToggle = () => {
        const newState = !open
        setOpen(newState)
        setDrawerOpen(newState)
    }

    const handleSelectMenu = (ids: string[]) => {
        setMenuSelection({
            ids,
        })
    }

    // set media wise responsive drawer
    useEffect(() => {
        if (drawerOpen === undefined) {
            setOpen(!matchDownLG)
            setDrawerOpen(!matchDownLG)
        }
    }, [matchDownLG])

    useEffect(() => {
        const drawerOpenOrDefault = drawerOpen !== undefined ? drawerOpen : true
        if (open !== drawerOpenOrDefault) setOpen(drawerOpenOrDefault)
    }, [drawerOpen])

    const drawerMenu = getMenu('DRAWER_MENU', data?.getMenuById)
    const navBarMenu = getMenu('TOP_APP_NAV_BAR_MENU', data?.getMenuById)

    return loading === false ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header
                open={open}
                navBarMenu={navBarMenu}
                handleDrawerToggle={handleDrawerToggle}
            />
            <Drawer
                open={open}
                menuSelection={menuSelection}
                menu={drawerMenu}
                selectMenu={handleSelectMenu}
                handleDrawerToggle={handleDrawerToggle}
            />
            <Box
                component="main"
                sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    ) : null
}

export default MainLayout
