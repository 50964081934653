// material-ui
import { styled } from '@mui/material/styles'
import LinearProgress from '@mui/material/LinearProgress'

// loader styled
const LoaderStyled = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2),
    },
}))

const Loading: React.FC = () => {
    return (
        <LoaderStyled>
            <LinearProgress color="primary" />
        </LoaderStyled>
    )
}

export default Loading
