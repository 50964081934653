import { SyntheticEvent, useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'

import MainCard from './MainCard'
import Transitions from './Transitions'
import TabPanel from './TabPanel'
import ProfileTab from './ProfileTab'
import SettingsTab from './SettingsTab'

import { logout } from '../supertokens'

// assets
import avatar1 from '../assets/images/users/avatar-1.png'
import {
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons'

const Profile: React.FC = () => {
    const theme = useTheme()
    const anchorRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    // const handleClose = (_: MouseEvent | TouchEvent) => {
    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (
        _: SyntheticEvent<Element, Event>,
        newValue: number,
    ) => {
        setValue(newValue)
    }

    const handleLogout = () => {
        void logout()
    }

    const iconBackColorOpen = 'grey.300'

    const tabProps = (index: number) => {
        return {
            id: `profile-tab-${index}`,
            'aria-controls': `profile-tabpanel-${index}`,
        }
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' },
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ p: 0.5 }}
                >
                    <Avatar
                        alt="profile user"
                        src={avatar1}
                        sx={{ width: 32, height: 32 }}
                    />
                    <Typography variant="subtitle1">John Doe</Typography>
                </Stack>
            </ButtonBase>
            {
                <Popper
                    placement="bottom-end"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 0],
                                },
                            },
                        ],
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                            {open && (
                                <Paper
                                    sx={{
                                        width: 290,
                                        minWidth: 240,
                                        maxWidth: 290,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250,
                                        },
                                    }}
                                >
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <MainCard
                                            elevation={0}
                                            border={false}
                                            content={false}
                                        >
                                            <CardContent
                                                sx={{ px: 2.5, pt: 3 }}
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Stack
                                                            direction="row"
                                                            spacing={1.25}
                                                            alignItems="center"
                                                        >
                                                            <Avatar
                                                                alt="profile user"
                                                                src={avatar1}
                                                                sx={{
                                                                    width: 32,
                                                                    height: 32,
                                                                }}
                                                            />
                                                            <Stack>
                                                                <Typography variant="h6">
                                                                    John Doe
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                >
                                                                    UI/UX
                                                                    Designer
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            size="large"
                                                            color="secondary"
                                                            onClick={
                                                                handleLogout
                                                            }
                                                        >
                                                            <LogoutOutlined />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            {open && (
                                                <>
                                                    <Box
                                                        sx={{
                                                            borderBottom: 1,
                                                            borderColor:
                                                                'divider',
                                                        }}
                                                    >
                                                        <Tabs
                                                            variant="fullWidth"
                                                            value={value}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            aria-label="profile tabs"
                                                        >
                                                            <Tab
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                                icon={
                                                                    <UserOutlined
                                                                        style={{
                                                                            marginBottom: 0,
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />
                                                                }
                                                                label="Profile"
                                                                {...tabProps(0)}
                                                            />
                                                            <Tab
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                                icon={
                                                                    <SettingOutlined
                                                                        style={{
                                                                            marginBottom: 0,
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />
                                                                }
                                                                label="Setting"
                                                                {...tabProps(1)}
                                                            />
                                                        </Tabs>
                                                    </Box>
                                                    <TabPanel
                                                        value={value}
                                                        index={0}
                                                        dir={theme.direction}
                                                    >
                                                        <ProfileTab
                                                            handleLogout={
                                                                handleLogout
                                                            }
                                                        />
                                                    </TabPanel>
                                                    <TabPanel
                                                        value={value}
                                                        index={1}
                                                        dir={theme.direction}
                                                    >
                                                        <SettingsTab />
                                                    </TabPanel>
                                                </>
                                            )}
                                        </MainCard>
                                    </ClickAwayListener>
                                </Paper>
                            )}
                        </Transitions>
                    )}
                </Popper>
            }
        </Box>
    )
}

export default Profile
