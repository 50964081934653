import * as React from 'react'
import { Route } from 'react-router'
import { Routes } from 'react-router-dom'
import * as ReactRouterDom from 'react-router-dom'
import SuperTokens from 'supertokens-auth-react'
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'

import {
    getSuperTokensConfig,
    getSuperTokensPreBuiltUIConfig,
} from '../supertokens'
import MainLayout from '../components/MainLayout'
import { appRoutes } from './config'

const NotFound = React.lazy(() => import('../pages/NotFound'))

// Initiliaze SuperTokens
SuperTokens.init(getSuperTokensConfig())

// Protected route
const ProtectedRoute: React.FC<React.PropsWithChildren<unknown>> = (
    props: React.PropsWithChildren<unknown>,
) => {
    return <SessionAuth>{props.children}</SessionAuth>
}

export const routes = (
    <Routes>
        {getSuperTokensRoutesForReactRouterDom(
            ReactRouterDom,
            getSuperTokensPreBuiltUIConfig(),
        )}
        <Route element={<MainLayout />}>
            {appRoutes.routes.map((route) => {
                const Component = route.element
                return route.protected ? (
                    <Route
                        key={route.url}
                        path={route.url}
                        element={
                            <ProtectedRoute>
                                <Component />
                            </ProtectedRoute>
                        }
                    />
                ) : (
                    <Route
                        key={route.url}
                        path={route.url}
                        element={<Component />}
                    />
                )
            })}
            {<Route path="*" element={<NotFound />} />}
        </Route>
    </Routes>
)
