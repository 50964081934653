import {
    ApolloClient,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client/core'

import { HttpLink } from '@apollo/client/link/http'

// create a httpLink
const httpLink: HttpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVICE_ENDPOINT,
    credentials: 'include',
})

// export the client as a window object if it does not exist
interface customWindow extends Window {
    'apollo-client': ApolloClient<NormalizedCacheObject>
}
declare const window: customWindow

let clientInstance: ApolloClient<NormalizedCacheObject>
if (!window['apollo-client']) {
    const newClient = new ApolloClient({
        name: 'react-web-client',
        link: httpLink,
        cache: new InMemoryCache(),
    })
    window['apollo-client'] = newClient
    clientInstance = newClient
} else clientInstance = window['apollo-client']

export const client: ApolloClient<NormalizedCacheObject> = clientInstance

/**
 * Create and configure apollo client
 */
// export const configureClient = () => {
//     const client = createClient({
//         url: process.env.REACT_APP_GRAPHQL_SERVICE_ENDPOINT,
//         fetchOptions: () => {
//             return {
//                 credentials: 'include',
//                 headers: {
//                     'content-type': 'application/json',
//                 },
//             }
//         },
//         exchanges: [cacheExchange, fetchExchange],
//     })
//     return client
// }
