// project import
import NavCard from './NavCard'
import NavMenu from './NavMenu'
import SimpleBar from './SimpleBar'
import { Menu, MenuSelection, SelectMenuHandler } from '../types/common'

interface IProps {
    readonly drawerOpen: boolean
    readonly menu: Menu
    readonly menuSelection: MenuSelection | null
    readonly selectMenu: SelectMenuHandler
}

const DrawerContent: React.FC<IProps> = (props: IProps) => {
    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <NavMenu {...props} />
            <NavCard />
        </SimpleBar>
    )
}

export default DrawerContent
