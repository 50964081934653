// material-ui
import { useTheme } from '@mui/material/styles'
import { Stack, Chip } from '@mui/material'

import DrawerHeaderStyled from './DrawerHeaderStyled'
import Logo from './Logo'

interface IProps {
    open: boolean
}

const DrawerHeader: React.FC<IProps> = (props: IProps) => {
    const theme = useTheme()
    const { open } = props
    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Logo />
                <Chip
                    label={process.env.REACT_APP_VERSION}
                    size="small"
                    sx={{
                        height: 16,
                        '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 },
                    }}
                    component="a"
                    href="https://google.com"
                    target="_blank"
                    clickable
                />
            </Stack>
        </DrawerHeaderStyled>
    )
}

export default DrawerHeader
