import Session from 'supertokens-auth-react/recipe/session'
import ThirdParty, {
    Google,
    signOut,
} from 'supertokens-auth-react/recipe/thirdparty'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui'
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui'
import { logger } from '../utils/logger'

export const getDomain = () => {
    const host = window.location.hostname
    const port = window.location.port
    if (port !== '0' && port !== '80' && port !== '443' && port !== '') {
        return host + ':' + port
    }
    return host
}
export const getAPIDomain = () => {
    return process.env.REACT_APP_AUTH_SERVICE_DOMAIN
}

// Logout SuperTokens session
export const logout = async () => {
    logger.log('Logout....')
    await signOut()
    window.location.href = '/'
}

// SuperTokens config
export const getSuperTokensConfig = () => {
    return {
        appInfo: {
            appName: 'SuperTokens Demo App',
            apiDomain: getAPIDomain(),
            websiteDomain: getDomain(),
            apiBasePath: '/auth',
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        recipeList: [
            ThirdParty.init({
                signInAndUpFeature: {
                    providers: [Google.init()],
                },
            }),
            EmailPassword.init(),
            Session.init(),
        ],
    }
}

// SuperTokens pre-built UI components
export const getSuperTokensPreBuiltUIConfig = () => {
    return [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI]
}
