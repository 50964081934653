import { forwardRef, ForwardedRef, ReactNode } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    SxProps,
} from '@mui/material'

// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
}

interface IProps {
    border?: boolean
    contentSX?: SxProps
    darkTitle?: boolean
    divider?: boolean
    elevation?: number
    action?: ReactNode
    content?: boolean
    sx?: SxProps
    children?: ReactNode
    title?: string
    secondary?: ReactNode
}

export interface MainCardProps extends IProps {}

const MainCard = forwardRef<HTMLDivElement, IProps>(
    (props: IProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
        const {
            border,
            // boxShadow,
            contentSX,
            darkTitle,
            divider,
            elevation,
            // action,
            content,
            sx,
            // shadow,
            title,
            secondary,
            children,
        } = props
        const theme = useTheme()
        // const boxShadowEffective =
        //    theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                sx={{
                    ...sx,
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    // borderColor:
                    //     theme.palette.mode === 'dark'
                    //         ? theme.palette.divider
                    //         : theme.palette.grey,
                    boxShadow: 'inherit',
                    ':hover': 'inherit',
                    // boxShadow:
                    //     boxShadowEffective &&
                    //     (!border || theme.palette.mode === 'dark')
                    //         ? shadow || theme.customShadows.z1
                    //         : 'inherit',
                    // ':hover': {
                    //     boxShadow: boxShadowEffective
                    //         ? shadow || theme.customShadows.z1
                    //         : 'inherit',
                    // },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                    },
                }}
            >
                {!darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        title={title}
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={<Typography variant="h3">{title}</Typography>}
                        action={secondary}
                    />
                )}
                {/* content & header divider */}
                {title && divider && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX}>{children}</CardContent>
                )}
                {!content && children}
            </Card>
        )
    },
)

MainCard.displayName = 'MainCard'

export default MainCard
