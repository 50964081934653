import { useMemo, ReactNode } from 'react'

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import Baseline from './baseline'
import Typography from './typography'

import ComponentsOverride from './overrides'

interface IProps {
    children?: ReactNode
}

const ThemeCustomization = (props: IProps): JSX.Element => {
    const { children } = props

    const theme = Baseline('light')

    const themeTypography = Typography(`'Public Sans', sans-serif`)

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536,
                },
            },
            // direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8,
                },
            },
            palette: theme.palette,
            typography: themeTypography,
            components: ComponentsOverride(theme),
        }),
        [theme, themeTypography /* , themeCustomShadows */],
    )
    const themes = createTheme(themeOptions)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeCustomization
