// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, IconButton, Link, useMediaQuery } from '@mui/material'

// project import
import Search from './HeaderSearch'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import { Menu } from '../types/common'
import { getIcon } from '../utils/icons'

// Nav bar menu props
interface IProps {
    menu: Menu
}

// Render nav bar menu
const renderNavBarMenu = (menu: Menu): React.ReactNode => {
    return (
        <>
            {menu.items.map((item) => {
                let itemTarget = '_self'
                if (item.target) {
                    itemTarget = '_blank'
                }

                return (
                    <IconButton
                        key={item.id}
                        component={Link}
                        href={item.url || '#'}
                        target={itemTarget}
                        disableRipple
                        color="secondary"
                        title={item.title || ''}
                        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
                        size="large"
                    >
                        {getIcon(item.icon)}
                    </IconButton>
                )
            })}
        </>
    )
}

const HeaderContent: React.FC<IProps> = (props: IProps) => {
    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
    const { menu } = props
    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            {renderNavBarMenu(menu)}
            <Notification />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    )
}

export default HeaderContent
