interface IProps {
    index: number
    value: number
    children?: React.ReactNode
    dir?: string
}

const TabPanel: React.FC<IProps> = (props: IProps) => {
    const { value, index, children } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
        >
            {value === index && children}
        </div>
    )
}

export default TabPanel
