// material-ui
import { Button, CardMedia, Link, Stack, Typography } from '@mui/material'
import avatar from '../assets/images/users/avatar-group.png'

// project import
import MainCard from './MainCard'

const NavCard: React.FC = () => {
    return (
        <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
            <Stack alignItems="center" spacing={2.5}>
                <CardMedia component="img" image={avatar} sx={{ width: 112 }} />
                <Stack alignItems="center">
                    <Typography variant="h5">Mantis Pro</Typography>
                    <Typography variant="h6" color="secondary">
                        Checkout pro features
                    </Typography>
                </Stack>
                <Button
                    component={Link}
                    target="_blank"
                    href="https://mantisdashboard.io"
                    variant="contained"
                    color="success"
                    size="small"
                >
                    Pro
                </Button>
            </Stack>
        </MainCard>
    )
}

export default NavCard
