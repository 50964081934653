import { ForwardedRef, forwardRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Icon,
    Avatar,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material'
import { MenuItem, MenuSelection, SelectMenuHandler } from '../types/common'

interface IProps {
    item: MenuItem
    drawerOpen: boolean
    level: number
    selectMenu: SelectMenuHandler
    menuSelection: MenuSelection | null
}

const NavItem: React.FC<IProps> = (props: IProps) => {
    const { item, level, drawerOpen, menuSelection, selectMenu } = props
    const theme = useTheme()

    let itemTarget = '_self'
    if (item.target) {
        itemTarget = '_blank'
    }

    const InnerComponent = forwardRef<HTMLAnchorElement>(
        (linkProps, ref: ForwardedRef<HTMLAnchorElement>) => (
            <Link
                {...linkProps}
                ref={ref}
                to={item.url || '#'}
                target={itemTarget}
            />
        ),
    )
    InnerComponent.displayName = 'NavItem.Component'

    const listItemProps =
        item.external === true
            ? {
                  component: 'a',
                  href: item.url,
                  target: itemTarget,
              }
            : {
                  // TODO:  (props, ref: ForwardedRef<HTMLAnchorElement>) => (
                  component: InnerComponent,
              }

    // if (item.external) {
    //     listItemProps = { component: 'a', href: item.url, target: itemTarget };
    // }

    // const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
    ) : undefined

    const isSelected =
        menuSelection != null
            ? menuSelection.ids.findIndex((id) => id === item.id) > -1
            : false

    // active menu item on page load
    useEffect(() => {
        const currentMenu = document.location.pathname === item.url
        if (currentMenu) {
            selectMenu([item.id])
        }
    }, [])

    const textColor = 'text.primary'
    const iconSelectedColor = 'primary.main'
    const avatar = item.chip?.avatar ? (
        <Avatar>{item.chip.avatar}</Avatar>
    ) : undefined

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => selectMenu([item.id])}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter',
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter',
                        },
                    },
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        bgcolor: 'transparent',
                    },
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter',
                            },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter',
                                },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography
                            variant="h6"
                            sx={{
                                color: isSelected
                                    ? iconSelectedColor
                                    : textColor,
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={avatar}
                />
            )}
        </ListItemButton>
    )
}

NavItem.displayName = 'NavItem'

export default NavItem
