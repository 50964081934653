import { useEffect, ReactNode } from 'react'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

interface IProps {
    children?: ReactNode
}

const ScrollTop: React.FC<IProps> = (props: IProps) => {
    const location = useLocation()
    const { pathname } = location

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, [pathname])

    return <React.Fragment>{props.children}</React.Fragment>
}

export default ScrollTop
