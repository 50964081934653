import { merge } from 'lodash';
import { Components, Theme } from '@mui/material';
import IconButton from './IconButton';
import OutlinedInput from './OutlinedInput';
import Chip from './Chip';
const ComponentsOverride = (theme: Theme): Components<Theme> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return merge(IconButton(theme), OutlinedInput(theme), Chip(theme));
};

export default ComponentsOverride;

