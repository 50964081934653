import * as React from 'react'
import { LazyExoticComponent } from 'react'
import { PageTypeEnum } from '../gql/graphql'

// Represents page route
export interface AppRoute {
    pageType: PageTypeEnum
    url: string
    protected: boolean
    element: LazyExoticComponent<React.FC>
}

// Represents app pages routes
export interface AppRoutes {
    routes: AppRoute[]
}

// Lazy load pages
const MyDailySummary = React.lazy(() => import('../pages/MyDailySummary'))
const MyFriends = React.lazy(() => import('../pages/MyFriends'))
const MyGroups = React.lazy(() => import('../pages/MyGroups'))
const MyCategories = React.lazy(() => import('../pages/MyCategories'))

// App pages routes
export const appRoutes: AppRoutes = {
    routes: [
        {
            pageType: PageTypeEnum.MyDailySummary,
            url: '/',
            protected: true,
            element: MyDailySummary,
        },
        {
            pageType: PageTypeEnum.MyFriends,
            url: '/my-friends',
            protected: true,
            element: MyFriends,
        },
        {
            pageType: PageTypeEnum.MyGroups,
            url: '/my-groups',
            protected: true,
            element: MyGroups,
        },
        {
            pageType: PageTypeEnum.MyCategories,
            url: '/my-categories',
            protected: true,
            element: MyCategories,
        },
    ],
}

// Get route by page type
export const getRouteByPageType = (
    pageType: PageTypeEnum,
): AppRoute | undefined => {
    return appRoutes.routes.find((route) => route.pageType === pageType)
}
