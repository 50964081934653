import { gql } from '@apollo/client'
export const GetMenuQueryDocument = gql(/* GraphQL */ `
    query GetMenu($ids: [ID!]!) {
        getMenuById(ids: $ids) {
            __typename
            id
            items {
                __typename
                id
                title
                icon
                type
                action {
                    ... on PageNavigationAction {
                        actionType
                        pageType
                    }
                    ... on ExternalNavigationAction {
                        actionType
                        url
                    }
                    ... on GenericNavigationAction {
                        actionType
                    }
                }
                items {
                    __typename
                    id
                    title
                    icon
                    type
                    action {
                        ... on PageNavigationAction {
                            actionType
                            pageType
                        }
                        ... on ExternalNavigationAction {
                            actionType
                            url
                        }
                        ... on GenericNavigationAction {
                            actionType
                        }
                    }
                }
            }
        }
    }
`)
