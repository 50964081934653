import { Menu, MenuItem, MenuItemType } from '../types/common'
import {
    MenuItem as MenuItemGraphQL,
    Menu as MenuGraphQL,
    MenuItemTypeEnum as MenuItemTypeGraphQL,
} from '../gql/graphql'
import { getRouteByPageType } from '../routes'
import { notEmpty } from './collections'

/** *
 * Get menu by id
 */
export const getMenu = (id: string, menus?: MenuGraphQL[] | null): Menu => {
    const filteredMenus = menus?.filter((x) => x.id === id)
    if (!filteredMenus || filteredMenus.length !== 1) {
        return { items: [] }
    }

    const filteredMenu = filteredMenus[0]

    const items: MenuItem[] =
        filteredMenu?.items?.filter(notEmpty).map((menuItem) => {
            const menuItemProps = getMenuItemProps(menuItem)
            return {
                items: menuItem.items
                    ?.filter(notEmpty)
                    .map((menuItem2) => getMenuItem(menuItem2)),
                ...menuItemProps,
            }
        }) || []

    return { items }
}

/** *
 * Get menu item
 **/
const getMenuItem = (menuItem: MenuItemGraphQL): MenuItem => {
    const menuItemProps = getMenuItemProps(menuItem)

    return {
        ...menuItemProps,
        items: menuItem.items
            ?.filter(notEmpty)
            .map((menuItem2) => getMenuItem(menuItem2)),
        ...menuItemProps,
        // chip: {
        //     label: '1',
        //     color: 'success',
        // },
    }
}

/**
 * Menu item props
 */
interface MenuItemProps {
    id: string
    title?: string | null
    icon?: string | null
    url?: string
    external: boolean
    disabled: boolean
    type: MenuItemType
}

/**
 * Get menu item props
 */
const getMenuItemProps = (menuItem: MenuItemGraphQL): MenuItemProps => {
    const { id, title, icon } = menuItem
    const disabled = false
    const type =
        menuItem.type === MenuItemTypeGraphQL.Group ||
        (menuItem.items && menuItem.items.length > 0)
            ? 'group'
            : 'item'
    let url: string | undefined
    let external = false
    if (menuItem.action) {
        if (menuItem.action.__typename === 'ExternalNavigationAction') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            url = menuItem.action.url
            external = true
        } else if (menuItem.action.__typename === 'PageNavigationAction') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
            url = getRouteByPageType(menuItem.action.pageType)?.url
            external = false
        }
    }

    return { id, title, icon, url, external, type, disabled }
}

export const LOCAL_STORAGE_DRAWER_OPEN = 'localStorage::drawerOpen'
