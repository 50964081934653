/* eslint-disable */
export const LOG_LEVEL =
    process.env.LOG_LEVEL ||
    (process.env.NODE_ENV === 'production' ? 'error' : 'log')

/** Signature of a logging function */
export type LogFn = (message?: any, ...optionalParams: any[]) => void

/** Basic logger interface */
export interface Logger {
    log: LogFn
    warn: LogFn
    error: LogFn
}

/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error'

const NO_OP: LogFn = (_message?: any, ..._optionalParams: any[]) => {
    // This is a no-op function that does nothing.
}

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn
    readonly warn: LogFn
    readonly error: LogFn

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {}

        // eslint-disable-next-line no-console
        this.error = console.error.bind(console)

        if (level === 'error') {
            this.warn = NO_OP
            this.log = NO_OP

            return
        }

        // eslint-disable-next-line no-console
        this.warn = console.warn.bind(console)

        if (level === 'warn') {
            this.log = NO_OP

            return
        }

        // eslint-disable-next-line no-console
        this.log = console.log.bind(console)
    }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL })
