import {
    QuestionCircleOutlined,
    UserOutlined,
    SettingOutlined,
} from '@ant-design/icons'
import { MenuIconEnum as IconTypeGraphQL } from '../gql/graphql'

/**
 * Get icon by type
 * @param icon
 * @returns
 */
export const getIcon = (icon?: string | null): React.ReactNode | null => {
    switch (icon) {
        case IconTypeGraphQL.Help:
            return <QuestionCircleOutlined />
        case IconTypeGraphQL.Profile:
            return <UserOutlined />
        case IconTypeGraphQL.Settings:
            return <SettingOutlined />
        default:
            return null
    }
}
