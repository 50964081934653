// material-ui
import { Box, Typography } from '@mui/material'

import NavGroup from './NavGroup'
import { Menu, MenuSelection, SelectMenuHandler } from '../types/common'

interface IProps {
    readonly drawerOpen: boolean
    readonly menu: Menu
    readonly menuSelection: MenuSelection | null
    readonly selectMenu: SelectMenuHandler
}

const NavMenu: React.FC<IProps> = (props: IProps) => {
    const { menu, drawerOpen, menuSelection, selectMenu } = props
    const navGroups = menu?.items.map((item) => {
        switch (item.type) {
            case 'group':
                return (
                    <NavGroup
                        key={item.id}
                        item={item}
                        drawerOpen={drawerOpen}
                        level={1}
                        menuSelection={menuSelection}
                        selectMenu={selectMenu}
                    />
                )
            default:
                return (
                    <Typography
                        key={item.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Fix - Navigation Group
                    </Typography>
                )
        }
    })

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>
}

export default NavMenu
