// material-ui
import { Theme, styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'

const drawerWidth = 200

//const openedMixin = (theme: Theme): Record<string, any> => ({
const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    // overflowX: 'hidden',
    boxShadow: 'none',
})

// const closedMixin = (theme: Theme): Record<string, any> => ({
const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: 'hidden',
    width: 0,
    borderRight: 'none',
})

interface IProps {
    open: boolean
}

const MiniDrawerStyled = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})<IProps>(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default MiniDrawerStyled
