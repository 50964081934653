import { Suspense } from 'react'
import { ApolloProvider } from '@apollo/client/react'
import ThemeCustomization from './themes'
import ScrollTop from './components/ScrollTop'
import Loading from './components/Loading'
import { routes } from './routes'
import { client } from './apollo/client'

export const App: React.FC = () => {
    return (
        <ApolloProvider client={client}>
            <ThemeCustomization>
                <ScrollTop>
                    <Suspense fallback={<Loading />}>{routes}</Suspense>
                </ScrollTop>
            </ThemeCustomization>
        </ApolloProvider>
    )
}

export default App
